.ta-booking__title {
  padding: 20px 0 15px;
}

.ta-booking .ta-title {
  border: none
}

.ta-booking .ta-accordion.expand .ta-accordion__title:after {
  content: "\F0D8";
  line-height: 1.8;
}

.ta-booking .ta-accordion .ta-accordion__title:after {
  content: "\F0D7";
  line-height: 2;
}

.ta-booking .ta-title__label,
.ta-title.small .ta-title__label {
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.5px;
  color: #505a5e;
}

.ta-booking .ta-title.small {
  margin: 0;
}

.ta-booking .ta-title__content {
  position: relative;
  margin-top: 8px;
}

.ta-booking .ta-form {
  padding-bottom: 0;
}

.ta-booking .ta-accordion:not(.expand)>.ta-bordered-box .ta-title__label {
  margin-bottom: 0;
}

.ta-booking__form .ta-loader img {
  width: 40px;
}

.ta-booking__form__content {
  background: #fff;
  padding: 8px;
  height: 100%;
  min-height: 150px;
  border-radius: 0 0 3px 3px;
  transition: all .2s;
}

.ta-booking__form-container.h-size-1000 .ta-booking__form__content,
.ta-booking__form-container.h-size-1200 .ta-booking__form__content {
  width: 300px;
}

.ta-booking__form-container.h-size-largest .ta-booking__form__content,
.ta-booking__form-container.h-size-2000 .ta-booking__form__content,
.ta-booking__form-container.h-size-1800 .ta-booking__form__content,
.ta-booking__form-container.h-size-1600 .ta-booking__form__content,
.ta-booking__form-container.h-size-1400 .ta-booking__form__content {
  width: 400px;
}

.ta-booking__form__content:before {
  /* content: ""; */
  position: absolute;
  top: 0;
  left: 3px;
  right: 3px;
  height: 15px;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
}

.ta-booking__form__content:after {
  /* content: ""; */
  position: absolute;
  bottom: 46px;
  left: 3px;
  right: 3px;
  height: 15px;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
}

.ta-booking__form__aside {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  width: 180px;
  background: #fff;
  z-index: 1;
  border-radius: 2px;
  padding: 6px;
  overflow: auto;
}

.ta-booking__form__content .ta-simple-tabs__header {
  position: absolute;
  top: -28px;
  left: 0;
  width: 305px;
  overflow: hidden;
  transition: all .2s;
}

.ta-booking__form-container.h-size-1000 .ta-booking__form__content .ta-simple-tabs__header,
.ta-booking__form-container.h-size-1200 .ta-booking__form__content .ta-simple-tabs__header {
  width: 305px;
}

.ta-booking__form-container.h-size-largest .ta-booking__form__content .ta-simple-tabs__header,
.ta-booking__form-container.h-size-2000 .ta-booking__form__content .ta-simple-tabs__header,
.ta-booking__form-container.h-size-1800 .ta-booking__form__content .ta-simple-tabs__header,
.ta-booking__form-container.h-size-1600 .ta-booking__form__content .ta-simple-tabs__header,
.ta-booking__form-container.h-size-1400 .ta-booking__form__content .ta-simple-tabs__header {
  width: 405px;
}

.ta-booking__form__content .ta-simple-tabs__header .ta-simple-tabs__header-item {
  /* width: calc((100% / 3) - 5px); */
  float: left;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 11px;
  background: #d4d6d7;
  opacity: 1;
}

.ta-booking__form__content .ta-simple-tabs__header .ta-simple-tabs__header-item.pull-right {
  float: right;
  margin-right: 5px;
}

.ta-booking__form__content .ta-simple-tabs__header .ta-simple-tabs__header-item .ta-hover-popup__content {
  font-size: 11px;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
}

.ta-booking__form__content .ta-simple-tabs__header .ta-simple-tabs__header-item.active {
  background: #fff;
}

.ta-booking__form__content .ta-simple-tabs__header .ta-simple-tabs__header-item.disabled {
  transform: translateY(20px) translateZ(0);
  z-index: -1;
  position: relative;
}

.ta-booking__form__content .ta-simple-tabs__body {
  margin-bottom: 0;
}

.ta-booking__form__content .ta-simple-tabs__body .ta-simple-tabs__body-item {
  padding: 0;
  background: #fff;
}

.ta-booking__form__content .ta-form {
  padding-bottom: 0;
}

.ta-booking__form__content .ta-btn {
  height: 30px;
  line-height: 30px;
}

.ta-booking__form__content .ta-form-buttons {
  margin-top: 8px;
  position: relative;
  z-index: 500;
}

/* SINGLE BOOKING FORM */

.ta-booking-form__scrollable-content {
  min-height: 250px;
  max-height: 505px;
  min-width: 284px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 12px;
  margin-top: -8px;
}

.ta-booking-form__scrollable-content .ta-form-section:last-child {
  margin-bottom: 0;
}

.ta-booking__form .ta-date-input .react-datepicker__time-container {
  width: 77px;
}

.ta-booking__form .ta-date-input.has-times .react-datepicker__navigation--next {
  right: 76px;
}

.ta-booking__form .ta-date-input .react-datepicker__current-month,
.ta-booking__form .ta-date-input .react-datepicker-time__header,
.ta-booking__form .ta-date-input .react-datepicker__header.react-datepicker__header--time {
  font-size: 11px;
}

.ta-booking__form .ta-date-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  font-size: 12px;
  line-height: 30px;
}

.ta-booking__form .ta-date-input.has-times .react-datepicker-popper {
  width: 279px;
}

.ta-booking__form .ta-date-input .react-datepicker__day,
.ta-booking__form .ta-date-input .react-datepicker__day-name,
.ta-booking__form .ta-date-input .react-datepicker__time-name {
  width: 19px;
  height: 19px;
  line-height: 19px;
}

.ta-booking__form .ta-date-input .react-datepicker__day.react-datepicker__day--disabled {
  opacity: 0.3;
}

.ta-booking__form .period-from .ta-date-input .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-left: -100px;
}

.ta-booking__form .period-until .ta-date-input .react-datepicker-popper {
  margin-left: -137px;
}

.ta-booking__form .period-until .ta-date-input .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-left: 100px;
}

.ta-booking__form .ta-date-input .react-datepicker__navigation:before {
  font-size: 14px;
}

.ta-booking__form .ta-multiple-select .Select-menu-outer,
.ta-booking__form .ta-single-select .Select-menu-outer {
  max-height: 150px;
}

.ta-booking__form .ta-multiple-select .Select-menu,
.ta-booking__form .ta-single-select .Select-menu {
  max-height: 138px;
}

.ta-booking__form .ta-accordion .ta-striped-title {
  margin-bottom: 0;
}

.ta-booking__form .ta-accordion.expand .ta-striped-title {
  margin-bottom: 8px;
}

.ta-booking__form .ta-color-select__options {
  top: 9px;
  right: 4px;
}

.ta-single-booking-form__service {
  z-index: 110;
}

.ta-booking__form .ta-form-text {
  padding-bottom: 2px;
}

/* ACCORDTION */

.ta-booking__form .ta-accordion {
  border: 1px solid rgba(182, 186, 189, 0.5);
  border-radius: 3px;
  padding: 8px;
  padding-top: 22px;
  margin-bottom: 8px;
  position: relative;
}

.ta-booking__form .ta-accordion__title {
  padding: 0 8px;
  font-size: 13px;
  color: #505A5E;
  font-weight: 500;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.ta-booking__form .ta-accordion .ta-accordion__title:after {
  background: #505A5E;
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 14px;
  color: #fff;
  text-align: center;
  font-size: 8px;
  right: 8px;
}

.ta-booking .ta-accordion .ta-accordion__body {
  margin-top: 0;
  height: 0;
  transition: all 0s;
  overflow: hidden;
}

.ta-booking .ta-accordion.expand .ta-accordion__body {
  margin-top: 8px;
  height: auto;
  overflow: visible;
}

.ta-booking__form .ta-accordion .ta-row:last-child {
  margin-bottom: 1px;
}

/* BUFFER TIMES */

.ta-buffer-times {
  padding-left: 70px;
  position: relative;
}

.ta-buffer-times__box {
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 60px;
  height: 98px;
  border-radius: 3px;
}

.ta-buffer-times__box__content {
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 0;
  width: 100%;
  border-radius: 3px;
}

.ta-booking-intervals-input .ta-form-group label,
.ta-buffer-times .ta-form-group label {
  left: 4px;
  padding: 0 2px;
}

.ta-booking__form .ta-accordion .ta-buffer-times .ta-striped-title,
.ta-booking__form .ta-accordion .ta-booking-intervals-input .ta-striped-title {
  margin-bottom: 4px;
}

.ta-booking__form .ta-accordion .ta-buffer-times .ta-striped-title__label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
}

/* REPEAT */

.ta-single-booking-form__repeat__custom .ta-form-text.ta-single-booking-form__repeat__custom__repeat-every {
  line-height: 30px;
  margin: 0;
  margin-top: 5px;
}

.ta-single-booking-form__repeat__custom__options {
  margin: 0 -4px;
  font-size: 0;
}

.ta-single-booking-form__repeat__custom__option {
  display: inline-block;
  margin: 4px;
  width: 31px;
}

.ta-single-booking-form__repeat__custom__option .ta-form-group {
  padding-top: 0;
}

.ta-single-booking-form__repeat__custom__option .ta-checkbox.box {
  display: block;
  font-size: 10px;
  padding: 0;
  text-align: center;
  text-transform: capitalize;
}

.ta-single-booking-form__repeat__custom .ta-row {
  margin-bottom: 0;
}

.ta-single-booking-form__repeat .ta-date-input .react-datepicker-popper {
  left: -81px !important;
}

.ta-single-booking-form__notify {
  margin-bottom: 15px;
}

/* ADDON */

.ta-booking__form.has-addon .ta-simple-tabs__header .ta-simple-tabs__header-item {
  background: #d4d6d7;
  transform: translateY(20px) translateZ(0);
  pointer-events: none;
}

.ta-booking__form__content .ta-simple-tabs__header .ta-simple-tabs__header-item.ta-booking__form__addon__label {
  background: #fff;
  display: none;
}

.ta-booking__form.has-addon .ta-simple-tabs__header .ta-simple-tabs__header-item.ta-booking__form__addon__label {
  display: inline-block;
  transform: translateY(0) translateZ(0);
}

.ta-booking__form__addon__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #fff;
  border-radius: 3px;
  display: none;
}

.ta-booking__form.has-addon .ta-booking__form__addon__content {
  display: block;
}

.ta-booking__form__addon__customer .ta-fixed-content:before {
  top: 0;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
}

.ta-booking__form__addon__customer .ta-fixed-content:after {
  content: "";
  position: fixed;
  bottom: 0;
  left: 15px;
  right: 15px;
  z-index: 10;
  height: 10px;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
}

.ta-booking__form__addon__customer .ta-fixed-content__body {
  top: 0;
}

.ta-booking__form__addon__customer .ta-form {
  padding-bottom: 0;
}

.ta-single-booking-form__participants__paid {
  background: #769851;
  color: #fff;
  height: 16px;
  line-height: 16px;
  padding: 0 4px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 500;
  transform: translateY(-3px);
}

.ta-single-booking-form__participants .ta-btn-participants-export {
  font-size: 14px;
  height: 32px;
  line-height: 32px;
}

.ta-participants-export-btn .ta-popup__content-inner {
  padding: 8px;
  overflow: visible;
}

.ta-participants-export-btn .ta-popup__content-inner .ta-row:last-of-type {
  margin-bottom: 0;
}

.ta-participants-export-btn .ta-course-export-btn--tooltip {
  width: 100%;
}

.ta-booking__form .ta-phone-input .ta-phone-input__countries {
  top: 32px;
}

.ta-single-booking-form__group-settings .ta-hover-popup {
  display: block;
}

.ta-single-booking-form__duration {
  margin-top: 8px;
}

.ta-single-booking-form__duration__warning.ta-message {
  margin-top: 8px;
}

.ta-single-booking-form__duration .ta-split-intervals__btn {
  width: 100%;
}

.ta-single-booking-form__duration .ta-split-intervals__btn .ta-hover-popup__content {
  text-align: center;
}

.ta-booking__form__aside__paid-online {
  background: #769851;
  color: #fff;
  line-height: 12px;
  padding: 8px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 500;
}

.ta-single-booking-form__duration .period-until .ta-form-control,
.ta-single-booking-form__duration .period-from .ta-form-control {
  font-size: 11px;
}

.ta-booking__form .ta-accordion__title {
  z-index: 0;
}

/* FULLSCREEN */

.ta-booking__form-container__content .ta-booking__form.fullscreen {
  position: absolute;
  top: 48px;
  left: 50%;
  bottom: 20px;
  background: #fff;
  border-radius: 0 0 3px 3px;
  width: calc(100% - 40px);
  min-width: 900px;
  max-width: 1400px;
  transform: translateX(-50%);
}

.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-booking__form__content .ta-simple-tabs__header {
  width: calc(100% + 5px);
}

.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-booking__form__content {
  width: 100%;
}

.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-course-booking-form .ta-form-buttons,
.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-single-booking-form .ta-form-buttons {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 15px;
  width: 100%;
  background: #fff;
  border-radius: 3px;
}

/* .ta-booking-form__col {
  width: 33.3%;
  padding: 8px;
  height: 100%;
  float: left;
  overflow: auto;
} */

.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-booking-form__scrollable-content {
  min-height: auto;
  max-height: 100%;
  min-width: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 8px 8px;
  margin-top: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50px;
}

.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-booking__form__content .ta-btn {
  height: 35px;
  line-height: 33px;
}

.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-booking__form__content .ta-course-booking-form.edit .ta-btn {
  height: 24px;
  line-height: 24px;
}

.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-btn.ta-select__options__category-button {
  height: 20px;
  line-height: 0;
}

.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-booking__form__content:after {
  bottom: 60px;
}

.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-single-booking-form__service {
  margin-top: -2px;
}

/* FAST BOOKING WIDGET */

.ta-booking__form-container.widget .ta-booking__form__content {
  width: 460px;
  border-radius: 0 3px 3px 3px;
}

.ta-booking__form-container.widget .ta-booking__form__content .ta-simple-tabs__header {
  width: 465px;
}

.ta-booking-form__widget-availability {
  position: relative;
  /* min-height: 250px; */
}

.ta-booking-form__steps-separator {
  border-top: 1px dotted #c1c8cd;
  margin: 16px 0;
}

.ta-booking-form__widget-availability__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.ta-booking-form__widget-availability__day {
  position: relative;
  background: #f4f3f3;
  border-radius: 3px;
  margin-bottom: 8px;
  padding-left: 90px;
  /* padding-right: 50px; */
}

.ta-booking-form__widget-availability__day__label {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 90px;
  background: rgba(80, 90, 94, 0.2);
  border-radius: 3px 0 0 3px;
  padding: 8px;
  font-size: 10px;
  font-weight: 500;
  color: #505a5e;
}

.ta-booking-form__widget-availability__day__label.active {
  background: rgb(149 197 166 / 20%);
}

.ta-booking-form__widget-availability__day__slots__no-results {
  text-align: center;
  padding: 0 8px 8px 0;
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
}

.ta-booking-form__widget-availability__day__slots {
  padding: 8px 0 0 8px;
}

.ta-booking-form__widget-availability__day__slots__slot {
  width: 76px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: #505a5e;
  color: #fff;
  border-radius: 3px;
  margin: 0 8px 8px 0;
  font-size: 11px;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ta-booking-form__widget-availability__day__slots__slot.disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}

.ta-booking-form__widget-availability__day__slots__slot.active {
  background: #95c5a6;
}

.ta-booking-form__widget-availability__title {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  margin: 16px 0;
  color: #505a5e;
}

.ta-booking-form__widget-availability .ta-calendar-week-input {
  width: 330px;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.ta-booking-form__widget-availability .ta-calendar-week-input__custom-input {
  height: 40px;
  line-height: 41px;
  border: 1px solid #d3d6d7;
  border-left: none;
  border-right: none;
  background: #fff;
  width: 250px;
}

.ta-booking-form__widget-availability .ta-calendar-week-input.open .ta-calendar-week-input__custom-input {
  transform: translateY(0) translateZ(0);
  border: 1px solid #d3d6d7;
  border-left: none;
  border-right: none;
  background: #fff;
  border-radius: 0;
}

.ta-booking-form__widget-availability .ta-calendar-week-input__btn {
  width: 40px;
  height: 40px;
  line-height: 41px;
  border: 1px solid #d3d6d7;
  background: #fff;
}

.ta-booking-form__widget-availability .ta-calendar-week-input__btn.disable {
  background: rgba(80, 90, 94, 0.16);
}

.ta-booking-form__widget-availability .ta-calendar-week-input__btn:first-child {
  border-radius: 3px 0 0 3px;
}

.ta-booking-form__widget-availability .ta-calendar-week-input__btn:last-child {
  border-radius: 0 3px 3px 0;
}

.ta-booking-form__widget-availability .ta-calendar-week-input-wrapper {
  z-index: 2;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
}


.ta-booking-form__widget-availability .ta-calendar-week-input {
  width: 330px;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.ta-booking-form__widget-availability .ta-calendar-week-input__custom-input {
  height: 40px;
  line-height: 41px;
  border: 1px solid #d3d6d7;
  border-left: none;
  border-right: none;
  background: #fff;
  width: 250px;
}

.ta-booking-form__widget-availability .ta-calendar-week-input.open .ta-calendar-week-input__custom-input {
  transform: translateY(0) translateZ(0);
  border: 1px solid #d3d6d7;
  border-left: none;
  border-right: none;
  background: #fff;
  border-radius: 0;
}

.ta-booking-form__widget-availability .ta-calendar-week-input__btn {
  width: 40px;
  height: 40px;
  line-height: 41px;
  border: 1px solid #d3d6d7;
  background: #fff;
}

.ta-booking-form__widget-availability .ta-calendar-week-input__btn.disable {
  background: rgba(80, 90, 94, 0.16);
}

.ta-booking-form__widget-availability .ta-calendar-week-input__btn:first-child {
  border-radius: 3px 0 0 3px;
}

.ta-booking-form__widget-availability .ta-calendar-week-input__btn:last-child {
  border-radius: 0 3px 3px 0;
}

.ta-booking-form__widget-availability .ta-calendar-week-input-wrapper {
  z-index: 2;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
}


.ta-booking-form__widget-availability__day__slots__selected {
  height: 30px;
  line-height: 30px;
  border-radius: 3px;
  padding: 0 8px;
  position: relative;
  background: #95c5a6;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 4px;
}

.ta-booking-form__widget-availability__day__slots__selected__clear {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: rgba(80, 90, 94, 0.7);
  font-size: 13px;
  cursor: pointer;
}

.ta-booking-form__widget-availability__next-available-day {
  background: #505a5e;
  height: 30px;
  line-height: 30px;
  padding: 0 8px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 8px;
  cursor: pointer;
}

.ta-booking-form__buttons__step-4 .ta-btn {
  margin-left: 8px;
}

.ta-booking-form__widget-success .ta-alert {
  max-width: 470px;
  max-height: 100%;
}

.ta-booking-form__widget-success .ta-hover-popup {
  width: 100%;
  text-align: center;
}

.ta-booking-form__widget-success .ta-simple-list-item {
  /* padding-left: 60px !important; */
  /* cursor: pointer !important; */
  text-align: left;
}

.ta-booking-form__widget-success__agent {
  position: absolute;
  top: 8px;
  left: 10px;
}

.ta-booking-form__widget-success__agent .ta-avatar {
  width: 42px;
  height: 42px;
  line-height: 42px;
  font-size: 12px;
  margin: 0;
}

.ta-booking-form__widget-success__agent .ta-avatar__image,
.ta-booking-form__widget-success__agent .ta-avatar__image img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.ta-booking-form__widget-success__agent__extra {
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 16px;
  background: #cb3b43;
  color: #fff;
  border: 1px solid #fff;
  position: absolute;
  top: -1px;
  left: 29px;
  font-size: 8px;
  font-weight: 800;
  text-align: center;
}

.ta-booking-form__widget-success .ta-message.active {
  max-height: inherit;
  margin-bottom: 8px;
}

.ta-booking-form__footfall-icon {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 7px;
}

/* CONFLICT ERROR */

.ta-error__recurring-conflicts {
  margin: 6px 0;
}

.ta-error__recurring-conflicts__agents {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  color: rgba(80, 90, 94, 0.7);
  position: relative;
}

.ta-error__recurring-conflicts__agents span {
  background: #FBEAEB;
  padding-right: 8px;
  position: relative;
  z-index: 2;
}

.ta-error__recurring-conflicts__agents:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 4px;
  border-top: 1px solid rgba(80, 90, 94, 0.25);
}

.ta-single-booking-form__edit__participants,
.ta-single-booking-form__participants__event {
  position: relative;
}

.ta-single-booking-form__edit__participants:after,
.ta-single-booking-form__participants__event:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 15px;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
}

.ta-single-booking-form__edit__participants .ta-simple-list-item,
.ta-single-booking-form__participants__event .ta-simple-list-item {
  background-color: #f4f3f3;
  border-color: #f4f3f3;
  z-index: 1000;
  margin: -5px;
  margin-bottom: 4px;
  padding: 8px;
  padding-right: 35px;
}

.ta-single-booking-form__edit__participants.no-button .ta-simple-list-item {
  padding-right: 8px;
}

.ta-single-booking-form__edit__participants .ta-simple-list-item {
  min-height: 10px;
}

.ta-single-booking-form__edit__participants .ta-simple-list-item:hover,
.ta-single-booking-form__participants__event .ta-simple-list-item:hover {
  background-color: #f4f3f3;
  border-color: #f4f3f3;
}

.ta-booking__form-container__content .ta-booking__form.fullscreen .ta-booking__form__content .ta-single-booking-form__participants__event__btn.ta-btn,
.ta-single-booking-form__participants__event__btn.ta-btn {
  padding: 0;
  width: 24px;
  text-align: center;
  height: 50px;
  line-height: 50px;
}

.ta-single-booking-form__edit__participants .ta-simple-list-item__title {
  margin-bottom: 0;
}

.ta-single-booking-form__edit__participants .ta-simple-list-item__title-text {
  transform: translateY(1px);
}

.ta-single-booking-form__edit__participants .ta-simple-list-item__title-icon {
  position: static;
}

.ta-single-booking-form__edit__participants__btn.ta-btn {
  padding: 0;
  width: 24px;
  text-align: center;
  height: 24px;
  line-height: 24px;
}

.ta-single-booking-form__participants__event__btn.ta-btn:hover {
  background: #cb3b43;
  border-color: #cb3b43;
  color: #fff;
}

.ta-single-booking-form__edit__participants__count {
  background: #a7acae;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 4px;
  border-radius: 3px;
}

.ta-course-booking-form.participants .ta-booking-form__scrollable-content {
  max-height: 440px;
  margin-top: 0;
}

.ta-course-booking-form.participants.fullscreen .ta-booking-form__scrollable-content {
  top: 60px;
}

.ta-course-booking-form.edit.fullscreen .ta-booking-form__scrollable-content {
  top: 30px;
}

.ta-export-bookings-form__wrapper {
  width: 540px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  transition: min-height .3s;
  padding: 15px;
}

.ta-export-bookings-form__wrapper .ta-title {
  margin-top: 0;
}

.ta-export-bookings-form__wrapper .ta-message.type-success.active {
  margin-top: 0;
}

.ta-export-bookings-form__wrapper .ta-form {
  padding-bottom: 0;
}

.ta-export-bookings-form__wrapper .ta-form .ta-form-buttons {
  display: flex;
  justify-content: space-between;
}

.ta-export-bookings-form__wrapper .ta-form .ta-form-buttons i {
  margin-right: 5px;
}

.ta-export-bookings-form__wrapper.success {
  min-height: 360px;
}

/* 
  these pointer events are needed in order to make
  onMouseLeave function of HoverTrigger work
  (nasty browser bug, see https://github.com/facebook/react/issues/4492#issuecomment-426356566)
*/

.ta-export-bookings-form__wrapper .ta-btn.ta-btn-secondary.disabled {
  font-weight: 700;
  pointer-events: none;
}

.ta-export-bookings-form__wrapper .ta-btn.ta-btn-primary {
  pointer-events: none;
}

.ta-export-bookings-form__wrapper .ta-btn:not(:disabled) {
  pointer-events: all;
}

.ta-single-booking-form__service-combination {
  margin-top: 8px;
}

.ta-single-booking-form__service-combination__service-color {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-top: 8px;
  border-radius: 8px;
  margin-right: 4px;
}

.ta-form-error.active.ta-service-combintaion-dependencies-error {
  margin-bottom: 8px;
}

.ta-booking_form-location__header-content .col-10,
.ta-booking_form-location__header-content .col-90 {
  vertical-align: middle;
}

.ta-booking_form-location__title {
  margin: 0 0 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  color: #505a5e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 345px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ta-booking_form-location__row {
  height: 13px;
  margin: 0 4px 0 0;
  font-size: 12px;
  text-align: left;
  color: #848b8e;
}

.ta-booking_form-location__row.right {
  margin-left: 2px;
}

.ta-booking_form-location__time {
  height: 12px;
  margin: 0 0 1px 4px;
  font-size: 12px;
  font-weight: 300;
  color: #848b8e;
}

.ta-booking_form-location__checkbox {
  margin-top: 8px;
  margin-bottom: 8px;
}

.ta-booking_form-location__key {
  height: 16px;
  margin: 0 4px 0 0;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: #505a5e;
}

.ta-booking_form-location__value {
  height: 16px;
  margin: 0 0 0 4px;
  font-family: Ubuntu;
  font-size: 12px;
  line-height: 1.33;
  color: #505a5e;
}

.ta-booking_form-location__key_value {
  padding: 10px;
}

.ta-booking_form-location__main_title {
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: #505a5e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}

.ta-booking_form-location__secondary_title {
  height: 16px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;
  color: #505a5e;
}

.ta-booking_form-location__subtitle {
  height: 12px;
  font-size: 11px;
  font-weight: 300;
  line-height: 1.09;
  text-align: right;
  color: #505a5e;
}

.ta-booking_form-location__locations_container {
  height: 48px;
  padding: 0 8px;
  border-radius: 3px;
  background-color: #e5e6e7;
  cursor: pointer;
  margin-top: 8px;
}

.ta-booking_form-location__locations_container:hover {
  background-color: #c5c8ca;
}

.ta-booking_form-location__locations_container:hover .ta-booking_form-location__select {
  background-color: #737b7e;
}

.ta-booking_form-location__select {
  height: 48px;
  padding: 16px 23px;
  border-radius: 0 3px 3px 0;
  background-color: #505a5e;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  right: -4px;
  cursor: pointer;
}

.ta-booking_form-location__locations_container>.ta-row .ta-col.col-70,
.ta-booking_form-location__locations_container>.ta-row .ta-col.col-30:not(.ta-booking_form-location__select) {
  padding-top: 6px;
}

.ta-booking_form-location__locations_container .ta-row {
  margin-bottom: 0;
}


.ta-booking_form-service-adhoc__time {
  height: 12px;
  margin: 0 0 1px 4px;
  font-size: 12px;
  font-weight: 300;
  color: #848b8e;
}

.ta-booking_form-service-adhoc__row {
  height: 13px;
  margin: 0 0 4px 0;
  font-size: 12px;
  text-align: left;
  color: #848b8e;
}

.ta-booking_form-service-adhoc__row.right {
  margin-left: 2px;
}

.ta-booking_form-service-adhoc__push_right {
  margin-right: 8px
}

.ta-booking_form-service-adhoc__title {
  margin: 0 0 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  color: #505a5e;
  max-width: 345px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ta-booking_form-service-adhoc__box_container {
  height: 48px;
  padding: 8px 0;
  width: 50px;
  /* margin: 1px 8px 0 0; */
  border-radius: 3px;
  background-color: rgba(104, 130, 176, 0.3);
  position: relative;
  z-index: 0;
}

.ta-booking_form-service-adhoc__box_container::after {
  content: " ";
  width: 100%;
  height: 32px;
  background-color: #6882B0;
  position: absolute;
  z-index: 1;
}

.ta-booking_form-service-adhoc__header-content .ta-buffer-times__box {
  height: 50px;
  width: 50px;
  top: 0;
  position: relative;
}

.ta-booking_form-service-adhoc__header-content .ta-buffer-times__box .ta-buffer-times__box__content {
  top: 0;
  bottom: 0;
  width: 50px;
}

.ta-booking_form-service-adhoc__header-content .ta-buffer-times__box .ta-buffer-times__box__content.top-offset {
  top: 10px;
}

.ta-booking_form-service-adhoc__header-content .ta-buffer-times__box .ta-buffer-times__box__content.bottom-offset {
  bottom: 10px;
}

.ta-booking_form-service-adhoc__header-content .col-10,
.ta-booking_form-service-adhoc__header-content .col-90 {
  vertical-align: middle;
}

.ta-booking_form-slot-preferences__time {
  height: 12px;
  margin: 0 0 1px 4px;
  font-size: 12px;
  font-weight: 300;
  color: #848b8e;
}

.ta-booking_form-slot-preferences__row {
  height: 13px;
  margin: 4px 4px 0 0;
  font-size: 12px;
  text-align: left;
  color: #848b8e;
}

.ta-booking_form-slot-preferences__row.flex {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  max-width: 100%;
}

.ta-booking_form-slot-preferences__avatars {
  display: flex;
  align-items: center;
  max-width: 100%;
  min-width: 0;
}

.ta-booking_form-slot-preferences__avatars i {
  margin-right: 9px;
}

.ta-booking_form-slot-preferences__row.right {
  margin-left: 2px;
}

.ta-booking_form-slot-preferences__push_right {
  margin-right: 8px
}

.ta-booking_form-slot-preferences__title {
  height: 16px;
  margin: 0 0 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.07;
  color: #505a5e;
}

.ta-customers__list .ta-btn__create-customer {
  font-weight: bold;
}

.ta-customers__list>.ta-popup__trigger {
  margin-top: 10px;
  width: 100%;
}

.ta-booking_form-location__buttons {
  margin-top: 8px;
}

.ta-booking_form-spinner-container {
  position: relative;
  height: 100px;
}

.ta-booking.is-customer-edit>.ta-accordion>.ta-bordered-box {
  margin-bottom: 20px;
}

.ta-booking__form-data-confirmation {
  padding: 8px 0;
}

.ta-booking-form__widget-success .ta-booking-form__success_list__path {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
  z-index: 1;
  margin-bottom: -7px;
}

.ta-booking-form__widget-success .ta-booking-form__success_list__oval {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: solid 1px #dde4da;
  background-color: #505a5e;
  z-index: 1;
}

.ta-booking-form__widget-success .ta-booking-form__success_list__dashed_line {
  width: 0;
  height: 20px;
  border-left: dashed 1px #505a5e;
  z-index: 1;
}

.ta-booking-form__section .ta-title.ta-title__compact {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.ta-booking-form__section .ta-title.ta-title__compact .ta-title__content {
  width: 100%;
}

.ta-booking-form__section .ta-title.ta-title__compact .ta-title__content .ta-booking-form__widget-availability {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ta-booking-form__section .ta-title.ta-title__compact .ta-title__content .ta-booking-form__widget-availability .ta-booking-form__widget-availability__day__slots__selected {
  flex: 1;
}

.ta-booking__dependencies__combination-service-title {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ta-booking__dependencies__combination-service-marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.ta-booking__dependencies__combination-service-name {
  color: #505A5E;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 12px;
}

.ta-booking-form__success_list_item .ta-booking-form__success_list_item__resources {
  display: inline-flex;
  align-items: center;
  flex:  1 0;
  min-width: 0;
}

.ta-booking-form__success_list_item .ta-booking-form__success_list_item__resources i {
  margin-right: 9px;
}

/* RESOURCE AVATARS */
.ta-booking-form__success__event__resources {
  direction: rtl;
  display: inline-flex;
  width: fit-content;
  max-width: 100%;
  min-width: 0;
}

.ta-booking-form__success__event__resources .ta-avatar {
  width: 14px;
  height: 14px;
  line-height: 14px;
  font-size: 6px;
  margin-bottom: 0;
  cursor: pointer;
  margin-left: -5px;
  outline: 2px solid #fff;
  flex-shrink: 0;
}

.ta-booking-form__success__event__resources .ta-hover-popup {
  max-width: 100%;
}

.ta-booking-form__success__event__resources .ta-hover-popup__content {
  white-space: normal;
}

.ta-booking-form__success__event__resources .ta-hover-popup__content {
  border-radius: 6px;
}

.ta-booking-form__success__event__resources .ta-hover-popup__content.top {
  transform: translateX(calc(-50% - 5px));
}

.ta-booking-form__success__event__resources .ta-hover-popup__content.bottom {
  transform: translateX(calc(-50% - 3px));
}

.ta-booking-form__success__event__resources .ta-hover-popup__trigger {
  height: 14px;
  min-width: 13px;
  max-width: 100%;
}

.ta-booking-form__success__event__resources-hover {
  font-size: 12px;
  color: #505a5e;
  line-height: 13px;
  text-transform: none;
  direction: ltr;
}

.ta-booking-form__success__event__resources-hover:not(:last-child) {
  margin-bottom: 4px;
}

.ta-booking-form__success__event__resources-hover-list__hover-popup .ta-hover-popup__content{
  width: 224px;
}

.ta-booking-form__success__event__resources-hover-list {
  text-transform: none;
  direction: ltr;
  text-align: initial;
  white-space: break-spaces;
}

.ta-booking-form__success__event__resources-hover-list .ta-booking-form__success__event__resources-hover {
  display: flex;
  align-items: center;
}

.ta-booking-form__success__event__resources-hover-list .ta-booking-form__success__event__resources-hover .ta-avatar {
  margin-left: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 8px;
}

.ta-booking-form__success__event__resources-hover-list .ta-avatar {
  margin-bottom: 0;
}

.ta-booking-form__success__event__resources-hover-list__sub-title {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #252729;
}

.ta-booking-form__success__event__resources-hover-list__category {
  margin-bottom: 12px;
}

.ta-booking-form__success__event__resources-hover-list__category:last-child {
  margin-bottom: 0;
}

.ta-booking-form__success__event__resources-hover__label {
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: rgba(37, 39, 41, 0.56);
  margin-left: 8px;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}

.ta-booking-form__success__event__resources img,
.ta-booking-form__success__event__resources-hover-list img,
.ta-booking-form__success__event__resources .ta-avatar__image,
.ta-booking-form__success__event__resources-hover-list .ta-avatar__image {
  width: 100%;
  height: 100%;
}

.ta-booking-form__success__event__resources-displayed-avatar {
  height: inherit;
  direction: ltr;
  display: flex;
  align-items: center;
  gap: 3px;
  max-width: 100%;
  min-width: 0;
}

.ta-booking__slot-preferences-header {
  display: flex;
  max-width: 100%;
  gap: 8px;
}

.ta-booking__slot-preferences-header__content {
  min-width: 0;
}

.ta-booking-form__success__event__resources-displayed-avatar__label {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 13px;
}

.ta-booking__high-zindex-section:hover {
  z-index: 9999;
}

.ta-booking__dependencies__alert .ta-form-text,
.ta-booking__dependencies__alert .ta-list.bullet-item,
.ta-booking__dependencies__alert .ta-list.bullet-item:before {
  color: #252729;
  line-height: 16px;
  word-break: normal;
}

.ta-booking__dependencies__alert .ta-list.bullet-item > span {
  display: inline;
}

.ta-booking__dependencies__alert .ta-form-text {
  font-size: 12px;
}